import applyLang from '@shapegames/core/dist/src/packages/helpers/apply-lang';
import { selectSystemLanguage } from '@shapegames/core/dist/src/packages/store/system/system-selectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Router from 'next/router';

interface RedirectProps {
  to: string;
  from?: string;
  as?: string;
}

function Redirect(props: RedirectProps) {
  let to = props.to;

  if (to[0] !== '/') {
    to = `/${props.to}`;
  }

  const lang = useSelector(selectSystemLanguage);

  useEffect(() => {
    Router.replace(to, applyLang(lang, props.as));
  }, [lang, props.as, to]);

  return null;
}

export default function Index() {
  return <Redirect to="/en/sports" />;
}
